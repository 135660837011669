import { colors } from "../colors";
import { getDaysInMonth } from "../general";

export const LegendTempAvailabilitySixHour = {
  title: "Received soundings",
  body: [
    {
      label: "At least one complete launch (all variables and layers)",
      color: colors.map.temp.availability.sixhour[0],
      filter: "cl-temp-avail-sixhour-all"
    },
    {
      label: "Incomplete launch (missing variables)",
      color: colors.map.temp.availability.sixhour[1],
      filter: "cl-temp-avail-sixhour-missing-var"
    },
    {
      label: "Incomplete launch (missing layers)",
      color: colors.map.temp.availability.sixhour[2],
      filter: "cl-temp-avail-sixhour-missing-level"
    },
    {
      label: "Not received in period",
      color: colors.map.temp.availability.sixhour[3],
      filter: "cl-avail-silent"
    },
    {
      label: "No match in OSCAR/Surface",
      color: colors.map.temp.availability.sixhour[4],
      filter: "cl-not-oscar",
      asterisk: "There is no corresponding station ID in OSCAR/Surface."
    }
  ]
};

export const LegendTempAvailabilityDaily = baseline => {
  let ret = {
    title: "Received soundings",
    body: [
      {
        label: "More than declared in OSCAR/Surface",
        color: colors.map.temp.availability.daily[0],
        filter: "cl-temp-avail-daily-more-than-100p"
      },
      {
        label: "No issue",
        color: colors.map.temp.availability.daily[1],
        filter: "cl-temp-avail-daily-all"
      },
      {
        label: "Completeness issue",
        color: colors.map.temp.availability.daily[2],
        filter: "cl-temp-avail-daily-missing-var"
      },
      {
        label: "Availability issue",
        color: colors.map.temp.availability.daily[3],
        filter: "cl-temp-avail-daily-missing-level"
      },
      {
        label: "Not received in period",
        color: colors.map.temp.availability.daily[4],
        filter: "cl-avail-silent"
      },
      {
        label: "No match in OSCAR/Surface",
        color: colors.map.temp.availability.daily[5],
        filter: "cl-not-oscar",
        asterisk: "There is no corresponding station ID in OSCAR/Surface."
      }
    ]
  };
  if (baseline === "GBON") {
    ret = {
      title: "Received soundings",
      body: [
        {
          label: "More than declared",
          color: colors.map.temp.availability.daily[0],
          filter: "cl-temp-avail-daily-more-than-100p-2-daily"
        },
        {
          label: "No issue",
          color: colors.map.temp.availability.daily[1],
          filter: "cl-temp-avail-daily-all-2-daily"
        },
        {
          label: "Completeness issue",
          color: colors.map.temp.availability.daily[2],
          filter: "cl-temp-avail-daily-missing-var-2-daily"
        },
        {
          label: "Availability issue",
          color: colors.map.temp.availability.daily[3],
          filter: "cl-temp-avail-daily-missing-level-2-daily"
        },
        {
          label: "Not received in period",
          color: colors.map.temp.availability.daily[4],
          filter: "cl-avail-silent"
        }
      ]
    };
  }
  return ret;
};

export const LegendTempAvailabilityMonthly = baseline => {
  let ret = {
    title: "Received complete soundings",

    body: [
      {
        label: "More than declared in OSCAR/Surface",
        color: colors.map.temp.availability.daily[0],
        filter: "cl-temp-avail-monthly-more-than-100p"
      },
      {
        label: "No issue (≥ 80%)",
        color: colors.map.temp.availability.daily[1],
        filter: "cl-temp-avail-monthly-all"
      },
      {
        label: "Availability issue (> 30%)",
        color: colors.map.temp.availability.daily[2],
        filter: "cl-temp-avail-monthly-missing-var"
      },
      {
        label: "Availability issue (< 30%)",
        color: colors.map.temp.availability.daily[3],
        filter: "cl-temp-avail-monthly-missing-level"
      },
      {
        label: "Not received in period",
        color: colors.map.temp.availability.daily[4],
        filter: "cl-avail-silent"
      },
      {
        label: "No match in OSCAR/Surface",
        color: colors.map.temp.availability.daily[5],
        filter: "cl-not-oscar",
        asterisk: "There is no corresponding station ID in OSCAR/Surface."
      }
    ]
  };
  // remove "No match in OSCAR/Surface" if baseline is 2-daily in TEMP
  // currently baseline is "GBON" as per old code
  // TODO rename baseline to be 2-daily and remove history of old GBON code.
  if (baseline === "GBON") {
    ret = {
      title: "Received complete soundings",

      body: [
        {
          label: "More than declared",
          color: colors.map.temp.availability.daily[0],
          filter: "cl-temp-avail-monthly-more-than-100p-2-daily"
        },
        {
          label: "No issue (≥ 80%)",
          color: colors.map.temp.availability.daily[1],
          filter: "cl-temp-avail-monthly-all-2-daily"
        },
        {
          label: "Availability issue (> 30%)",
          color: colors.map.temp.availability.daily[2],
          filter: "cl-temp-avail-monthly-missing-var-2-daily"
        },
        {
          label: "Availability issue (< 30%)",
          color: colors.map.temp.availability.daily[3],
          filter: "cl-temp-avail-monthly-missing-level-2-daily"
        },
        {
          label: "Not received in period",
          color: colors.map.temp.availability.daily[4],
          filter: "cl-avail-silent"
        }
      ]
    };
  }
  return ret;
};

export const LegendTempAvailabilityDailyGbon = {
  title: "Received complete soundings",
  body: [
    {
      label: "Complete",
      color: colors.map.temp.availability.daily[1],
      filter: "cl-gbon-temp-avail-daily-all",
      asterisk: "2 or more complete soundings"
    },
    {
      label: "Availability issues",
      color: colors.map.temp.availability.daily[2],
      filter: "cl-gbon-temp-avail-small-issues",
      asterisk: "Only one complete sounding"
    },
    {
      label: "Not received in period",
      color: colors.map.temp.availability.daily[4],
      filter: "cl-avail-silent",
      asterisk: "Zero complete soundings"
    }
  ]
};
export const LegendTempAvailabilityMonthlyGbon = {
  title: "Received complete soundings",
  body: [
    {
      label: "Complete (\u2265 80%)",
      color: colors.map.temp.availability.daily[1],
      filter: "cl-gbon-temp-avail-daily-all",
      asterisk: "Depend on number of days aggregated for that month"
    },
    {
      label: "Availability issues (\u2265 30%)",
      color: colors.map.temp.availability.daily[2],
      filter: "cl-gbon-temp-avail-small-issues",
      asterisk: "Depend on number of days aggregated for that month"
    },
    {
      label: "Availability issues (< 30%)",
      color: colors.map.temp.availability.daily[3],
      filter: "cl-gbon-temp-avail-big-issues",
      asterisk: "Depend on number of days aggregated for that month"
    },
    {
      label: "Not received in period",
      color: colors.map.temp.availability.daily[4],
      filter: "cl-avail-silent",
      asterisk: "Zero soundings"
    },
    {
      label: "Less than 10 days",
      color: colors.map.synop.alertStroke,
      stroke: true
    }
  ]
};

export const legendTempMonthlyGbon = date => {
  const nbDays = getDaysInMonth(date);
  const expectedGBON = nbDays * 2;
  let expected_80_percent = Math.round((80 * expectedGBON) / 100);
  let expected_30_percent = Math.round((30 * expectedGBON) / 100);

  return {
    title: "Received complete soundings",
    body: [
      {
        label: "Complete (\u2265 80%)",
        color: colors.map.temp.availability.daily[1],
        filter: "cl-gbon-temp-avail-daily-all",
        asterisk: expected_80_percent + " or more complete soundings"
      },
      {
        label: "Availability issues (\u2265 30%)",
        color: colors.map.temp.availability.daily[2],
        filter: "cl-gbon-temp-avail-small-issues",
        asterisk:
          "more than " +
          expected_30_percent +
          " complete soundings and less than " +
          expected_80_percent
      },
      {
        label: "Availability issues (< 30%)",
        color: colors.map.temp.availability.daily[3],
        filter: "cl-gbon-temp-avail-big-issues",
        asterisk:
          "more than or equal one sounding and less than or equal " +
          expected_30_percent
      },
      {
        label: "Not received in period",
        color: colors.map.temp.availability.daily[4],
        filter: "cl-avail-silent",
        asterisk: "Zero soundings"
      }
    ]
  };
};
// Quality
const commonTitle = "Observation and model differences\nRoot Mean Square Error";
export const LegendTempQualityHumidity = {
  title: `${commonTitle} (%)`,
  body: [
    {
      label: "> 30",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-0p3"
    },
    {
      label: "15 < x \u2264 30",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-0p15-le-0p3"
    },
    {
      label: "10 < x \u2264 15",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-0p1-le-0p15"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-0p05-le-0p1"
    },
    {
      label: "2 < x \u2264 5",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-0p02-le-0p05"
    },
    {
      label: "\u2264 2",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-0p02"
    }
  ]
};

export const LegendTempQualityHumidityMonthly = {
  title: `${commonTitle} (%)`,
  body: [
    {
      label: "> 30",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-0p3"
    },
    {
      label: "15 < x \u2264 30",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-0p15-le-0p3"
    },
    {
      label: "10 < x \u2264 15",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-0p1-le-0p15"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-0p05-le-0p1"
    },
    {
      label: "2 < x \u2264 5",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-0p02-le-0p05"
    },
    {
      label: "\u2264 2",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-0p02"
    },
    {
      label: "Less than 10 days",
      color: colors.map.synop.alertStroke,
      stroke: true
    }
  ]
};
export const LegendTempQualityWind = {
  title: `${commonTitle} (m/s)`,
  body: [
    {
      label: "> 15",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-15"
    },
    {
      label: "8 < x \u2264 15",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-8-le-15"
    },
    {
      label: "5 < x \u2264 8",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-5-le-8"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-1-le-3"
    },
    {
      label: "\u2264 1",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-1"
    }
  ]
};
export const LegendTempQualityWindMonthly = {
  title: `${commonTitle} (m/s)`,
  body: [
    {
      label: "> 15",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-15"
    },
    {
      label: "8 < x \u2264 15",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-8-le-15"
    },
    {
      label: "5 < x \u2264 8",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-5-le-8"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-1-le-3"
    },
    {
      label: "\u2264 1",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-1"
    },
    {
      label: "Less than 10 days",
      color: colors.map.synop.alertStroke,
      stroke: true
    }
  ]
};

export const LegendTempQualityTemperature = {
  title: `${commonTitle} (K)`,
  body: [
    {
      label: "> 10",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-10"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-5-le-10"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-1-le-3"
    },
    {
      label: "0.5 < x \u2264 1",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-0p5-le-1"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-0p5"
    }
  ]
};

export const LegendTempQualityTemperatureMonthly = {
  title: `${commonTitle} (K)`,
  body: [
    {
      label: "> 10",
      color: colors.map.temp.quality[5],
      filter: "cl-temp-quality-gt-10"
    },
    {
      label: "5 < x \u2264 10",
      color: colors.map.temp.quality[4],
      filter: "cl-temp-quality-gt-5-le-10"
    },
    {
      label: "3 < x \u2264 5",
      color: colors.map.temp.quality[3],
      filter: "cl-temp-quality-gt-3-le-5"
    },
    {
      label: "1 < x \u2264 3",
      color: colors.map.temp.quality[2],
      filter: "cl-temp-quality-gt-1-le-3"
    },
    {
      label: "0.5 < x \u2264 1",
      color: colors.map.temp.quality[1],
      filter: "cl-temp-quality-gt-0p5-le-1"
    },
    {
      label: "\u2264 0.5",
      color: colors.map.temp.quality[0],
      filter: "cl-temp-quality-le-0p5"
    },
    {
      label: "Less than 10 days",
      color: colors.map.synop.alertStroke,
      stroke: true
    }
  ]
};
