import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "antd";

import { getDaysInMonth } from "../../../utils/general";
import { getMarineQualityClassFilter } from "../../../utils/mapFilters/nwp";

import "./ToggleOscarMarine.css";
import infoIcon from "../../../images/icons/info.svg";

// returns the index of an array inside another array, -1 otherwise
// source: https://stackoverflow.com/questions/6315180/javascript-search-array-of-arrays
function indexOfArray(val, array) {
  var hash = {};
  for (let i = 0; i < array.length; i++) {
    hash[array[i]] = i;
  }
  return hash.hasOwnProperty(val) ? hash[val] : -1;
}

const ToggleOscarMarine = props => {
  let prefix = "";
  const ftype = props[prefix + props.fileType];

  // Get the number of days in the month
  // this is used for the filter in GSN/Completeness
  const nbDays = getDaysInMonth(ftype.selectedDate);

  const filterLayer = event => {
    const classes = event.target.className.split(" ");
    const filterLabel = event.target.getAttribute("data-filter");
    const layerName = props.map.getStyle().layers[
      props.map.getStyle().layers.length - 1
    ].id;
    let buoy_layer;
    let ship_layer;
    if (props.fileType === "marine_surface") {
      buoy_layer = props.map.getStyle().layers[
        props.map.getStyle().layers.length - 2
      ].id;

      ship_layer = props.map.getStyle().layers[
        props.map.getStyle().layers.length - 1
      ].id;
    }

    const currentFilter = props.map.getFilter(layerName);
    let newFilter;

    if (props.fileType === "marine_surface") {
      newFilter = getMarineQualityClassFilter(filterLabel);
    }
    // Adding the class back
    if (classes.includes("unselected")) {
      event.target.classList.remove("unselected");

      const index = indexOfArray(newFilter, currentFilter);

      currentFilter.splice(index, 1);

      if (props.fileType === "marine_surface") {
        props.map.setFilter(buoy_layer, currentFilter);
        props.map.setFilter(ship_layer, currentFilter);
      } else {
        props.map.setFilter(layerName, currentFilter);
      }
    } else {
      // filtering out the class
      event.target.classList.add("unselected");
      let f;
      if (currentFilter) {
        currentFilter.push(newFilter);
        f = currentFilter;
      } else {
        f = ["all", newFilter];
      }
      if (props.fileType === "marine_surface") {
        props.map.setFilter(buoy_layer, f);
        props.map.setFilter(ship_layer, f);
      } else {
        props.map.setFilter(layerName, f);
      }
    }
  };
  const title = props.legendContent.title ? (
    <h4>{props.legendContent.title}</h4>
  ) : null;

  const body = props.legendContent.body.map(e => {
    let asterisk = null;
    if (e.asterisk) {
      asterisk = (
        <Tooltip placement="rightBottom" title={e.asterisk}>
          <img
            src={infoIcon}
            alt="Icon info legend element"
            className="toggleoscar-info"
          />
        </Tooltip>
      );
    }
    let styleClass = { backgroundColor: e.color };
    let actionOnClick = filterLayer;
    let classLegend = "toggleoscar-class-clickable";
    if (e.stroke) {
      styleClass = { border: `1px solid ${e.color}` };
      actionOnClick = null;
      classLegend = "toggleoscar-class-not-clickable";
    }
    return (
      <div key={e.color} className="toggleoscar_class-row">
        <div
          className={classLegend}
          key={e.color}
          onClick={actionOnClick}
          data-filter={e.filter}
          aria-label="Only show marine assets without OSCAR/Surface metadata (inactive before 26/3/2025)"
          title="Only show marine assets without OSCAR/Surface metadata (inactive before 26/3/2025)"
        >
          <span className="toggleoscar-element-style" style={styleClass} />
          {e.label}
        </div>
        {asterisk}
      </div>
    );
  });

  const visible = props.isVisible ? "block" : "none";

  const divStyle = {
    display: visible
  };
  return (
    <div className="toggleoscar" style={divStyle}>
      {title}
      {body}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    map: state.map,
    synop: state.synop,
    temp: state.temp,
    gbon_synop: state.gbon_synop,
    gbon_temp: state.gbon_temp,
    guan: state.guan,
    gsn: state.gsn,
    buoy: state.buoy,
    ship: state.ship,
    marine_surface: state.marine_surface
  };
};

export default connect(mapStateToProps)(ToggleOscarMarine);
