import React, { useEffect, useState } from "react";
import { mean } from "d3-array";
import moment from "moment";

import {
  formatDataSimpleFromAPI,
  formatDataCombinedFromAPI,
  mergeDataSources,
  pickProperties
} from "../../../utils/popup";

import {
  getPeriodsAsQueryParameters,
  getMarinePeriodsAsQueryParameters,
  filterArray,
  allCenters,
  TempVariablesShortName,
  TempCompletenessStatus,
  dateNewNCEPSYNOP,
  getDaysInMonth
} from "../../../utils/general";

import {
  fetchData,
  URL_SYNOP_AGGREGATED_OBSERVATIONS_POPUP,
  URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES,
  URL_STATION,
  URL_TEMP_AGGREGATED_OBSERVATIONS_POPUP,
  URL_BUOY_AGGREGATED_OBSERVATIONS_POPUP,
  URL_SHIP_AGGREGATED_OBSERVATIONS_POPUP,
  URL_TEMP_OBSERVATIONS_POPUP
} from "../../../utils/api";

import LinkTimeSeries from "../LinkTimeSeries/LinkTimeSeries";
import LinkOSCAR from "../LinkOSCAR/LinkOSCAR";
import LinkMarineRoute from "../LinkMarineRoute/LinkMarineRoute";
import { Spin, Table } from "antd";

import "./Popup.css";
import { size } from "lodash";

export const addClassToPopup = () => {
  const popupElement = document.querySelector(".mapboxgl-popup-content");
  if (popupElement) {
    popupElement.classList.add("mapboxgl-popup-content-big");
  }
};

function check_tsi_wsi(d) {
  let labelId = "TSI";
  if (d.value.includes("-")) {
    labelId = "WSI";
  }
  return (
    <li key={labelId}>
      {labelId}: {d.value}
    </li>
  );
}

function getCompletenessText(props, availability) {
  let status = TempCompletenessStatus[availability];
  // check if the current period is monthly
  if (props.selectedPeriodType === "monthly") {
    if (props.stationObject.availability >= 1) {
      status = "Full";
    }
  }
  return status;
}

function fetchBuoyPopupInfo(props, periodsAsQP, linkTimeSeries, setContent) {
  let url = `${URL_BUOY_AGGREGATED_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&period_type=${props.selectedPeriodType}&report=${props.selectedReport}&station_id=${props.stationObject.station_id}&${periodsAsQP}&var_id=${props.selectedVariable}`;
  fetchData(url).then(response => {
    if (response.data.length > 0) {
      // PART 1 of the pop-up (Station name and WIGOS ID)
      let labelID = "TSI";
      if (response.data[0].in_oscar) {
        labelID = "WSI";
      }
      props.onSetSelectedStation({
        name: response.data[0].station,
        wigosid: response.data[0].wigosid,
        in_oscar: response.data[0].in_oscar
      });

      const part1 = [
        `Name: ${response.data[0].station}`,
        `${labelID}: ${response.data[0].wigosid}`
      ];

      // PART 2 of the pop-up (values per center and level)
      let centers = [];
      let centersValues = [];
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        if (!centers.includes(element.center)) {
          centers.push(element.center);
          centersValues.push({
            type: "title",
            value: element.center
          });
        }

        const toHighligth =
          Math.round(Math.abs(element.avg_bg_dep) * 100000) / 100000 ===
          Math.round(Math.abs(props.stationObject.avg_bg_dep) * 100000) /
            100000;
        let avg_bg_value = Math.abs(element.avg_bg_dep).toFixed(5);
        // For 2m relative humidity we multiply the value by 100 to represent it as a percentage
        if (props.selectedVariable == 58) {
          avg_bg_value = avg_bg_value * 100;
        }
        centersValues.push({
          type: "value",
          value: `Value: ${avg_bg_value}`,
          highlight: toHighligth
        });
      }

      const htmlPart1 = part1.map(v => {
        return <li key={v}>{v}</li>;
      });

      const htmlPart2 = centersValues.map(k => {
        let classElement;
        if (k.type === "title") {
          classElement = "popup-title-center";
        }
        if (k.highlight) {
          classElement = "popup-value-highlighted";
        }
        return (
          <li key={k.value} className={classElement}>
            {k.value}
          </li>
        );
      });

      const htmlContent = (
        <>
          <span>
            <b>BUOY</b>
          </span>
          {htmlPart1}
          {htmlPart2}
        </>
      );

      let linkOSCAR;

      let links = linkTimeSeries;

      let linkMarineRoute = (
        <>
          <LinkMarineRoute
            file_type="buoy"
            period_type={props.selectedPeriodType}
            report={props.selectedReport}
            station_id={props.stationObject.station_id}
            periodsAsQP={periodsAsQP}
            var_id={props.selectedVariable}
            wigosid={response.data[0].wigosid}
            selectedSixHPeriod={props.selectedSixHPeriod}
            selectedDate={props.selectedDate}
            centers={props.periodData[props.selectedPeriodType].centers}
            selectedCenter={props.selectedCenter}
          />
        </>
      );
      linkOSCAR = (
        <>
          <LinkOSCAR wigosid={response.data[0].wigosid} />
        </>
      );
      if (!response.data[0].in_oscar) {
        // make oscar link not clickable
        linkOSCAR = (
          <a
            className="isDisabled link-oscar"
            target="_blank"
            rel="noopener noreferrer"
            href="#"
            onClick={e => e.preventDefault()}
          >
            Open in OSCAR
          </a>
        );
      }
      links = (
        <>
          {linkTimeSeries}
          <br />
          {linkMarineRoute}
          <br />
          <hr />
          {linkOSCAR}
        </>
      );

      setContent(
        <ul className="popup-station-properties">
          {htmlContent}
          {links}
        </ul>
      );
    }
  });
}

function fetchShipPopipInfo(props, periodsAsQP, linkTimeSeries, setContent) {
  fetchData(
    `${URL_SHIP_AGGREGATED_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&period_type=${props.selectedPeriodType}&report=${props.selectedReport}&station_id=${props.stationObject.station_id}&${periodsAsQP}&var_id=${props.selectedVariable}`
  ).then(response => {
    // PART 1 of the pop-up (Station name and WIGOS ID)
    if (response.data.length > 0) {
      let labelID = "TSI";
      if (response.data[0].in_oscar) {
        labelID = "WSI";
      }
      props.onSetSelectedStation({
        name: response.data[0].station,
        wigosid: response.data[0].wigosid,
        in_oscar: response.data[0].in_oscar
      });

      const part1 = [
        `Name: ${response.data[0].station}`,
        `${labelID}: ${response.data[0].wigosid}`
      ];

      // PART 2 of the pop-up (values per center and level)
      let centers = [];
      let centersValues = [];
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        if (!centers.includes(element.center)) {
          centers.push(element.center);
          centersValues.push({
            type: "title",
            value: element.center
          });
        }
        const toHighligth =
          Math.round(Math.abs(element.avg_bg_dep) * 100000) / 100000 ===
          Math.round(Math.abs(props.stationObject.avg_bg_dep) * 100000) /
            100000;
        let avg_bg_value = Math.abs(element.avg_bg_dep).toFixed(5);
        // For 2m relative humidity we multiply the value by 100 to represent it as a percentage
        if (props.selectedVariable == 58) {
          avg_bg_value = avg_bg_value * 100;
        }
        centersValues.push({
          type: "value",
          value: `Value: ${avg_bg_value}`,
          highlight: toHighligth
        });
      }

      const htmlPart1 = part1.map(v => {
        return <li key={v}>{v}</li>;
      });

      const htmlPart2 = centersValues.map(k => {
        let classElement;
        if (k.type === "title") {
          classElement = "popup-title-center";
        }
        if (k.highlight) {
          classElement = "popup-value-highlighted";
        }
        return (
          <li key={k.value} className={classElement}>
            {k.value}
          </li>
        );
      });

      const htmlContent = (
        <>
          <span>
            <b>SHIP</b>
          </span>
          {htmlPart1}
          {htmlPart2}
        </>
      );

      let linkOSCAR;

      let links = linkTimeSeries;

      let linkMarineRoute = (
        <>
          <LinkMarineRoute
            file_type="ship"
            period_type={props.selectedPeriodType}
            report={props.selectedReport}
            station_id={props.stationObject.station_id}
            periodsAsQP={periodsAsQP}
            var_id={props.selectedVariable}
            wigosid={response.data[0].wigosid}
            selectedSixHPeriod={props.selectedSixHPeriod}
            selectedDate={props.selectedDate}
            centers={props.periodData[props.selectedPeriodType].centers}
            selectedCenter={props.selectedCenter}
          />
        </>
      );
      linkOSCAR = (
        <>
          <LinkOSCAR wigosid={response.data[0].wigosid} />
        </>
      );
      if (!response.data[0].in_oscar) {
        // make oscar link not clickable
        linkOSCAR = (
          <a
            className="isDisabled link-oscar"
            target="_blank"
            rel="noopener noreferrer"
            href="#"
            onClick={e => e.preventDefault()}
          >
            Open in OSCAR
          </a>
        );
      }
      links = (
        <>
          {linkTimeSeries}
          <br />
          {linkMarineRoute}
          <br />
          <hr />
          {linkOSCAR}
        </>
      );

      setContent(
        <ul className="popup-station-properties">
          {htmlContent}
          {links}
        </ul>
      );
    }
  });
}

const Popup = props => {
  const [content, setContent] = useState(<Spin tip="Loading..." />);

  const center = props.combinedMode ? "all" : props.selectedCenter;
  let dateUntil;
  if (props.selectedPeriodType === "six_hour") {
    dateUntil = props.selectedDate + " " + props.selectedSixHPeriod + ":00:00";
  } else if (
    props.selectedPeriodType === "daily" ||
    props.selectedPeriodType === "alert"
  ) {
    dateUntil = props.selectedDate + " 00:00:00";
  } else if (props.selectedPeriodType === "monthly") {
    dateUntil = props.selectedDate + "-01 00:00:00";
  }

  const dataParams = {
    station: props.stationObject.station_id,
    date: dateUntil,
    center
  };

  let TSTypes = "single";
  // For TEMP/Quality/Combined there are 2 time-series links: 1 for Stra, 1 for Trop
  if (
    props.fileType === "temp" &&
    props.selectedReport === "quality" &&
    props.combinedMode
  ) {
    // TSTypes = ["Stra", "Trop"];
    TSTypes = [
      { id: "Stra", name: "Stra" },
      { id: "Trop", name: "Trop" }
    ];
  }
  // For monthly quality we have two time series for gross error and rmse in case of combined mode
  else if (
    props.fileType === "synop" &&
    props.selectedReport === "quality" &&
    props.selectedPeriodType === "monthly" &&
    props.combinedMode
  ) {
    TSTypes = [
      { id: "rms", name: "RMSE" },
      { id: "gross_error_percent", name: "GE %" }
    ];
  }
  const linkTimeSeries = (
    <LinkTimeSeries
      type={TSTypes}
      fileType={props.fileType}
      data={dataParams}
      onSetChartData={props.onSetChartData}
      setVisible={props.setVisible}
      selectedReport={props.selectedReport}
      combinedMode={props.combinedMode}
      selectedVariable={props.selectedVariable}
      selectedPeriodType={props.selectedPeriodType}
      onSetLevel={props.onSetLevel}
      baseline={props.baseline}
      stationObject={props.stationObject}
    />
  );

  let expectedGBON;
  if (props.baseline === "GBON") {
    let valueGBON;
    if (props.fileType === "synop") {
      if (props.selectedPeriodType === "six_hour") {
        valueGBON = 6;
      } else if (props.selectedPeriodType === "daily") {
        valueGBON = 24;
      } else if (props.selectedPeriodType === "monthly") {
        // to be discussed with Timo
        valueGBON = props.stationObject.nr_expected;
      }
    } else if (props.fileType === "temp") {
      if (props.selectedPeriodType === "six_hour") {
        valueGBON = 0;
      } else if (props.selectedPeriodType === "daily") {
        valueGBON = 2;
      } else if (props.selectedPeriodType === "monthly") {
        // to be discussed with Timo
        valueGBON = props.stationObject.nr_expected;
      }
    }
    if (valueGBON) {
      expectedGBON = { name: "#Expected", value: valueGBON };
    }
  }

  let multiplier = 1;
  // For 2m relative humidity we multiply the value by 100 to represent it as a percentage
  // 58 = "2m relative humidity" (SYNOP)
  // 29 = "2m relative humidity" (TEMP)
  if (props.selectedVariable === "58" || props.selectedVariable === "29") {
    multiplier = 100;
  }

  const report = props.selectedReport;

  let gbon_monthly_note =
    "The number expected can vary by center in case of missing data at the center";
  // Build the pop-up content
  useEffect(() => {
    if (props.fileType === "synop") {
      if (
        (props.combinedMode || props.selectedReport === "availability") &&
        props.selectedPeriodType !== "alert"
      ) {
        const variable_point =
          props.selectedReport === "availability"
            ? props.stationObject.var_id
            : props.selectedVariable;
        fetchData(
          `${URL_SYNOP_AGGREGATED_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&report=${report}&period_type=${props.selectedPeriodType}&station_id=${props.stationObject.station_id}&${periodsAsQP}&var_id=${variable_point}`
        )
          .then(response => {
            let returnedData = response.data;
            const in_oscar = props.stationObject.in_oscar
              ? props.stationObject.in_oscar
              : response.data[0].in_oscar;

            // Used to fill in the title of the chart component
            props.onSetSelectedStation({
              name: returnedData[0].station,
              wigosid: returnedData[0].wigosid,
              in_oscar
            });

            // for availaibility/combined/surface pressure
            // we need to filter the data first to only keep one of
            // surface pressure and geopotentail per center
            if (
              props.combinedMode &&
              props.selectedReport === "availability" &&
              props.selectedVariable === "110" &&
              props.selectedPeriodType !== "monthly"
            ) {
              returnedData = filterArray(
                returnedData,
                ["center"],
                "nr_received",
                {
                  var_id: 1
                }
              );
            }
            let formattedReturn;

            if (props.combinedMode) {
              formattedReturn = formatDataCombinedFromAPI(
                props,
                returnedData,
                props.selectedReport,
                in_oscar,
                multiplier
              );
              // // In monthly view, we add note after wigos id statement so at index 2 in the formattedReturn.data array
              // if (
              //   props.selectedPeriodType === "monthly" &&
              //   props.selectedReport === "availability"
              // ) {
              //   formattedReturn.data.splice(2, 0, {
              //     name: "nr_received_note",
              //     value: "Number received / Number expected"
              //   });
              // }
            } else {
              formattedReturn = formatDataSimpleFromAPI(props, returnedData);
            }
            // Add "Geopotential" at the end of the pop-up
            // TODO: for combined mode
            if (
              !props.combinedMode &&
              props.fileType === "synop" &&
              props.selectedReport === "availability" &&
              props.stationObject.var_id === 1
            ) {
              formattedReturn.data.push({
                name: "Geopotential",
                value: "Geopotential"
              });
            }

            // In case of SYNOP/Combined/Quality/Humidity
            // remove NCEP from the popup
            if (
              props.combinedMode &&
              props.fileType === "synop" &&
              props.selectedReport === "quality" &&
              props.selectedVariable === "58" &&
              moment.utc(props.selectedDate).format("YYYY-MM-DD") <
                moment.utc(dateNewNCEPSYNOP).format("YYYY-MM-DD")
            ) {
              formattedReturn.data.forEach((d, i) => {
                if (d.name === "#NCEP") {
                  formattedReturn.data.splice(i, 1);
                }
              });
            }
            if (expectedGBON) {
              formattedReturn.data.forEach((d, i) => {
                if (
                  props.selectedPeriodType === "monthly" &&
                  props.selectedReport === "availability"
                ) {
                  let centers = ["#DWD", "#ECMWF", "#JMA", "#NCEP"];
                  if (centers.includes(d.name)) {
                    formattedReturn.data[i] = {
                      name: d.name,
                      // value: d.value + " / " + d.nr_expected
                      value: d.value
                    };
                  }
                }
                if (d.name === "#Expected") {
                  // formattedReturn.data.splice(i, 1);
                  formattedReturn.data[i] = expectedGBON;
                  // if (props.selectedPeriodType !== "monthly") {
                  //   formattedReturn.data[i] = expectedGBON;
                  // }
                  //  else {
                  //   formattedReturn.data[i] = {
                  //     name: "Note",
                  //     value: gbon_monthly_note
                  //   };
                  // }
                }
                // In the case of GBON we remove the entry
                // "Default schedule" if any
                if (d.name === "Default schedule") {
                  formattedReturn.data.splice(i, 1);
                }
              });
              // In monthly view, we add note after wigos id statement so at index 2 in the formattedReturn.data array
            }

            let htmlContent;
            let max = 0;
            let maxIndex = 0;
            let min = 1000000;
            let minIndex = 0;
            formattedReturn.data.forEach((d, i) => {
              if (
                props.selectedPeriodType === "monthly" &&
                props.selectedReport === "availability"
              ) {
                let centers = ["#DWD", "#ECMWF", "#JMA", "#NCEP"];

                if (centers.includes(d.name)) {
                  if (d.value > max) {
                    max = d.value;
                    maxIndex = i;
                  }

                  formattedReturn.data[i] = {
                    name: d.name,
                    value:
                      // d.value + " / " + d.nr_expected + " " + d.geopotential
                      d.value
                  };
                }
              }
              if (
                props.selectedPeriodType === "monthly" &&
                props.selectedReport === "quality"
              ) {
                let centers = ["#DWD", "#ECMWF", "#JMA", "#NCEP"];
                if (centers.includes(d.name)) {
                  let rms = (d.rms / 1).toFixed(2);
                  let gross_error_percent = d.nr_gross_error / d.nr_received;
                  if (isNaN(rms)) {
                    rms = "No Data";
                  } else if (rms < min) {
                    min = rms;
                    minIndex = i;
                  }
                  if (isNaN(gross_error_percent)) {
                    gross_error_percent = "No data";
                  }
                  // multiply gross error percent by 100 to get percentage
                  gross_error_percent = (gross_error_percent * 100).toFixed(2);
                  let classElement = "popup-title-center";
                  formattedReturn.data[i] = {
                    name: <b>{d.name}</b>,
                    value: (
                      <ul key={d.name}>
                        <li key={d.name + "rms"}> RMSE: {rms}</li>
                        <li key={d.name + "ge"}> GE%: {gross_error_percent}</li>
                      </ul>
                    )
                  };
                }
              }
              if (d.name === "#Expected") {
                // formattedReturn.data.splice(i, 1);
                // if (
                //   props.selectedPeriodType === "monthly" &&
                //   props.combinedMode
                // ) {
                //   formattedReturn.data[i] = {
                //     name: "Note",
                //     value: gbon_monthly_note
                //   };
                // }
              }
            });
            let i = -1;
            htmlContent = formattedReturn.data.map(d => {
              i += 1;
              if (d.name === "NWP-ID") {
                return check_tsi_wsi(d);
              }

              if (d.name === "nr_received_note") {
                return (
                  <li key={d.name}>
                    <b>{d.value}</b>
                  </li>
                );
              }
              let value = d.value;
              if (maxIndex === i) {
                value = <b>{d.value}</b>;
              } else if (minIndex === i) {
                value = <b>{d.value}</b>;
              }
              return d.name === "Default schedule" ||
                d.name === "Geopotential" ? (
                <li key={d.name}>{d.name}</li>
              ) : (
                <li key={d.name}>
                  {d.name}: {value}
                </li>
              );
            });
            // }

            let links = linkTimeSeries;
            if (formattedReturn.oscar_id !== null) {
              links = (
                <>
                  {linkTimeSeries}
                  <br />
                  <hr />
                  <LinkOSCAR wigosid={formattedReturn.oscar_id} />
                </>
              );
            }
            setContent(
              <ul className="popup-station-properties">
                {htmlContent}
                {links}
              </ul>
            );
          })
          .catch(error => {
            console.log("ERROR API");
            console.log(error);
          });
      } else if (props.selectedPeriodType !== "alert") {
        fetchData(
          `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
        )
          .then(response => {
            let dataFeature;
            if (props.selectedReport === "availability") {
              dataFeature = pickProperties(props.stationObject, [
                "nr_received",
                "nr_expected"
              ]);
            } else {
              dataFeature = pickProperties(props.stationObject, [
                "avg_bg_dep",
                "std_bg_dep",
                "rms"
              ]);
            }

            // Used to fill in the title of the chart component
            props.onSetSelectedStation({
              name: response.data.name,
              wigosid: response.data.wigosid,
              in_oscar: response.data.in_oscar
            });

            const dataAPI = pickProperties(response.data, ["name", "wigosid"]);

            const formattedReturn = mergeDataSources(
              props.selectedReport,
              dataFeature,
              dataAPI,
              multiplier,
              props.selectedPeriodType
            );
            let htmlContent = formattedReturn.data.map(d => {
              if (d.name === "NWP-ID") {
                return check_tsi_wsi(d);
              }
              return (
                <li key={d.name}>
                  {d.name}: {d.value}
                </li>
              );
            });

            let links = linkTimeSeries;
            if (response.data.name !== "Unknown") {
              links = (
                <>
                  {linkTimeSeries}
                  <br />
                  <hr />
                  <LinkOSCAR wigosid={formattedReturn.oscar_id} />
                </>
              );
            }

            setContent(
              <ul className="popup-station-properties">
                {htmlContent}
                {links}
              </ul>
            );
          })
          .catch(error => {
            console.log("ERROR API");
            console.log(error);
          });
      } else {
        // Alert
        if (props.combinedMode) {
          const promise1 = fetchData(
            `${URL_SYNOP_AGGREGATED_OBSERVATIONS_TIMESERIES}/?baseline=${props.baseline}&report=${report}&period_type=daily&station=${props.stationObject.station_id}&variable=${props.selectedVariable}&date=${dateUntil}&center=${center}&nb_days=4`
          );

          const promise2 = fetchData(
            `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
          );
          Promise.all([promise1, promise2])
            .then(function(responses) {
              const obsData = responses[0].data;
              const stationInfo = responses[1].data;

              // Used to fill in the title of the chart component
              props.onSetSelectedStation({
                name: stationInfo.name,
                wigosid: stationInfo.wigosid,
                in_oscar: stationInfo.in_oscar
              });

              const valuesByCenter = [];
              allCenters.forEach(v => {
                valuesByCenter.push({
                  name: v,
                  values: []
                });
              });

              obsData.forEach(v => {
                const element = valuesByCenter.filter(c => {
                  return c.name === v.center;
                })[0];
                if (v.avg_bg_dep !== null) {
                  element.values.push(Math.abs(v.avg_bg_dep * multiplier));
                }
              });

              let labelID = "WIGOS-ID";

              if (stationInfo.name === "Unknown") {
                labelID = "TSI";
                if (stationInfo.wigosid.includes("-")) {
                  labelID = "WSI";
                }
              }

              const htmlPart1 = (
                <>
                  <li key={1}>Name: {stationInfo.name}</li>
                  <li key={2}>
                    {labelID}: {stationInfo.wigosid}
                  </li>
                </>
              );

              // In case of Combined/Humidity
              // remove NCEP from the popup
              if (
                props.selectedReport === "quality" &&
                props.selectedVariable === "58"
              ) {
                valuesByCenter.forEach((d, i) => {
                  if (d.name === "NCEP") {
                    valuesByCenter.splice(i, 1);
                  }
                });
              }

              const htmlPart2 = valuesByCenter.map(d => {
                const meanValue =
                  d.values.length === 0
                    ? "No data"
                    : Math.round(mean(d.values) * 1000) / 1000;
                const nbObs =
                  d.values.length === 0
                    ? null
                    : `(${d.values.length} daily values)`;
                return (
                  <li key={d.name}>
                    {d.name}: {meanValue} {nbObs}
                  </li>
                );
              });

              const htmlContent = (
                <>
                  {htmlPart1}
                  {htmlPart2}
                </>
              );

              let links = linkTimeSeries;
              if (stationInfo.name !== "Unknown") {
                links = (
                  <>
                    {linkTimeSeries}
                    <br />
                    <hr />
                    <LinkOSCAR wigosid={stationInfo.wigosid} />
                  </>
                );
              }

              setContent(
                <ul className="popup-station-properties">
                  {htmlContent}
                  {links}
                </ul>
              );
            })
            // To improve
            .catch(error => console.log(`Error in executing ${error}`));
        } else {
          fetchData(
            `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
          )
            .then(response => {
              // Used to fill in the title of the chart component
              props.onSetSelectedStation({
                name: response.data.name,
                wigosid: response.data.wigosid,
                in_oscar: response.data.in_oscar
              });

              let labelID = "WIGOS-ID";

              if (response.data.name === "Unknown") {
                labelID = "TSI";
                if (response.data.wigosid.includes("-")) {
                  labelID = "WSI";
                }
              }
              const htmlContent = (
                <>
                  <li key={1}>Name: {response.data.name}</li>
                  <li key={2}>
                    {labelID}: {response.data.wigosid}
                  </li>
                  <li key={3}>
                    Value:{" "}
                    {Math.round(
                      props.stationObject.avg_bg_dep * multiplier * 1000
                    ) / 1000}
                  </li>
                  <li key={4}>#Daily values: {props.stationObject.nb_obs}</li>
                </>
              );

              let links = linkTimeSeries;
              if (response.data.name !== "Unknown") {
                links = (
                  <>
                    {linkTimeSeries}
                    <br />
                    <hr />
                    <LinkOSCAR wigosid={response.data.wigosid} />
                  </>
                );
              }

              setContent(
                <ul className="popup-station-properties">
                  {htmlContent}
                  {links}
                </ul>
              );
            })
            .catch(error => {
              console.log("ERROR API");
            });
        }
      }
    } else if (props.fileType === "marine_surface") {
      // fetch buoy data
      if (props.stationObject.file_type === "buoy") {
        fetchBuoyPopupInfo(props, periodsAsQP, linkTimeSeries, setContent);
      } else {
        fetchShipPopipInfo(props, periodsAsQP, linkTimeSeries, setContent);
      }
    } else {
      // TEMP
      if (props.selectedReport === "availability") {
        if (props.selectedPeriodType === "six_hour") {
          addClassToPopup();
          const promise1 = fetchData(
            `${URL_TEMP_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&station=${props.stationObject.station_id}&${periodsAsQP}`
          );

          const promise2 = fetchData(
            `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
          );

          Promise.all([promise1, promise2])
            .then(function(responses) {
              const obsData = responses[0].data;
              const stationInfo = responses[1].data;
              let labelID = "WIGOS-ID";
              let linkOSCAR;

              if (stationInfo.name === "Unknown") {
                labelID = "TSI";
                if (stationInfo.wigosid.includes("-")) {
                  labelID = "WSI";
                }
              } else {
                linkOSCAR = (
                  <>
                    <LinkOSCAR wigosid={stationInfo.wigosid} />
                  </>
                );
              }
              const links = (
                <>
                  {linkTimeSeries}
                  <br />
                  <hr />
                  {linkOSCAR}
                </>
              );

              let htmlPart1 = (
                <>
                  <li key={stationInfo.name}>Name: {stationInfo.name}</li>
                  <li key={stationInfo.wigosid}>
                    {labelID}: {stationInfo.wigosid}
                  </li>
                </>
              );

              const centersAvailable = [];
              const newObsData = [];
              obsData.forEach(d => {
                if (!centersAvailable.includes(d.center)) {
                  centersAvailable.push(d.center);
                  newObsData.push({
                    title: d.center,
                    data: []
                  });
                }

                const indexCenter = centersAvailable.indexOf(d.center);
                newObsData[indexCenter].data.push({
                  date: d.date.slice(0, -1),
                  levels: d.levels,
                  var_id: TempVariablesShortName[d.var_id],
                  obs_type: d.obs_type ? d.obs_type : "n/a",
                  status: d.status === 0 ? "Used" : "Not used"
                });
              });

              const columns = [
                {
                  title: "Date",
                  dataIndex: "date",
                  key: "date"
                },
                {
                  title: "Layer",
                  dataIndex: "levels",
                  key: "levels"
                },
                {
                  title: "Variable",
                  dataIndex: "var_id",
                  key: "var_id"
                },
                {
                  title: "Obs. Type",
                  dataIndex: "obs_type",
                  key: "obs_type"
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status"
                }
              ];

              let htmlPart2 = newObsData.map((d, i) => {
                const title = () => d.title;
                let table;
                if (props.combinedMode) {
                  table = (
                    <Table
                      key={i}
                      rowKey={record => Math.random()}
                      dataSource={d.data}
                      columns={columns}
                      pagination={false}
                      title={title}
                    />
                  );
                } else {
                  table = (
                    <Table
                      key={i}
                      rowKey={record => Math.random()}
                      dataSource={d.data}
                      columns={columns}
                      pagination={false}
                    />
                  );
                }
                return table;
              });
              if (newObsData.length === 0) {
                htmlPart2 = "No data";
              }

              // Used to fill in the title of the chart component
              props.onSetSelectedStation({
                name: stationInfo.name,
                wigosid: stationInfo.wigosid,
                in_oscar: props.stationObject.in_oscar
              });

              setContent(
                <>
                  <ul className="popup-station-properties">{htmlPart1}</ul>
                  {links}
                  <br />
                  <br />
                  {htmlPart2}
                </>
              );
            })
            // To improve
            .catch(error => console.log(`Error in executing ${error}`));
        } else if (
          (props.selectedPeriodType === "daily" ||
            props.selectedPeriodType === "monthly") &&
          !props.combinedMode
        ) {
          fetchData(
            `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
          )
            .then(response => {
              let labelID = "WIGOS-ID";
              let linkOSCAR;
              if (response.data.name === "Unknown") {
                labelID = "TSI";
                if (response.data.wigosid.includes("-")) {
                  labelID = "WSI";
                }
              } else {
                linkOSCAR = (
                  <>
                    <hr />
                    <LinkOSCAR wigosid={response.data.wigosid} />
                  </>
                );
              }

              // Used to fill in the title of the chart component
              props.onSetSelectedStation({
                name: response.data.name,
                wigosid: response.data.wigosid,
                in_oscar: props.stationObject.in_oscar
              });

              let htmlPart1 = (
                <>
                  <li key={1}>Name: {response.data.name}</li>
                  <li key={2}>
                    {labelID}: {response.data.wigosid}
                  </li>
                </>
              );

              let baseline_expected = props.stationObject.nr_expected;
              if (
                props.baselinetxt === "2-daily" &&
                props.selectedPeriodType === "daily"
              ) {
                baseline_expected = 2;
              } else if (
                props.baselinetxt === "2-daily" &&
                props.selectedPeriodType === "monthly"
              ) {
                const nbDays = getDaysInMonth(props.selectedDate);
                baseline_expected = nbDays * 2;
              }
              let objExpected = {
                name: "#Expected",
                value: baseline_expected
              };
              if (expectedGBON) {
                objExpected = expectedGBON;
              }
              // don't show label empty for completeness
              let completenessLi = "";
              if (props.stationObject.availability !== 0) {
                completenessLi = (
                  <li key={1}>
                    Completeness:{" "}
                    {getCompletenessText(
                      props,
                      props.stationObject.availability
                    )}
                  </li>
                );
              }
              let htmlPart2 = (
                <ul className="popup-station-properties">
                  {completenessLi}
                  <li key={2}>#Received: {props.stationObject.nr_received}</li>
                  <li key={3}>
                    {objExpected.name}: {objExpected.value}
                  </li>
                  <li key={4}>
                    {props.baseline !== "GBON" &&
                    props.stationObject.default_schedule
                      ? "Default schedule"
                      : null}
                  </li>
                </ul>
              );

              const links = (
                <>
                  {linkTimeSeries}
                  <br />
                  {linkOSCAR}
                </>
              );

              setContent(
                <>
                  <ul className="popup-station-properties">{htmlPart1}</ul>

                  {htmlPart2}

                  {links}
                </>
              );
            })
            .catch(error => {
              console.error(
                `Couldn't fetch the data for the TEMP station with the id: ${props.stationObject.station_id} from: ${URL_STATION}`
              );
            });
        } else if (
          (props.selectedPeriodType === "daily" ||
            props.selectedPeriodType === "monthly") &&
          props.combinedMode
        ) {
          const promise1 = fetchData(
            `${URL_TEMP_AGGREGATED_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&period_type=${props.selectedPeriodType}&report=${props.selectedReport}&station_id=${props.stationObject.station_id}&${periodsAsQP}&var_id=${props.selectedVariable}`
          );

          const promise2 = fetchData(
            `${URL_STATION}?network=${props.fileType}&id=${props.stationObject.station_id}`
          );

          Promise.all([promise1, promise2]).then(function(responses) {
            const obsData = responses[0].data;
            const stationInfo = responses[1].data;

            let labelID = "WIGOS-ID";
            let linkOSCAR;
            if (stationInfo.name === "Unknown") {
              labelID = "TSI";
              if (stationInfo.wigosid.includes("-")) {
                labelID = "WSI";
              }
            } else {
              linkOSCAR = (
                <>
                  <hr />
                  <LinkOSCAR wigosid={stationInfo.wigosid} />
                </>
              );
            }

            // Used to fill in the title of the chart component
            props.onSetSelectedStation({
              name: stationInfo.name,
              wigosid: stationInfo.wigosid,
              in_oscar: props.stationObject.in_oscar
            });

            let htmlPart1 = (
              <>
                <li key={1}>Name: {stationInfo.name}</li>
                <li key={2}>
                  {labelID}: {stationInfo.wigosid}
                </li>
              </>
            );

            let baseline_expected = props.stationObject.nr_expected;
            if (
              props.baselinetxt === "2-daily" &&
              props.selectedPeriodType === "daily"
            ) {
              baseline_expected = 2;
            } else if (
              props.baselinetxt === "2-daily" &&
              props.selectedPeriodType === "monthly"
            ) {
              const nbDays = getDaysInMonth(props.selectedDate);
              baseline_expected = nbDays * 2;
            }
            let objExpected = {
              name: "#Expected",
              value: baseline_expected
            };
            // if (props.selectedPeriodType === "monthly" && props.combinedMode) {
            //   objExpected = { name: "Note", value: gbon_monthly_note };
            // } else
            if (expectedGBON) {
              objExpected = expectedGBON;
            }

            let htmlPart2 = "";
            let htmlExpectedMonthly = "";
            let htmlNoteMonthly = "";
            if (props.selectedPeriodType === "monthly") {
              htmlExpectedMonthly = (
                <div>
                  {objExpected.name}: {objExpected.value}
                </div>
              );
              // htmlNoteMonthly = (
              //   <div>
              //     {"Note: " + gbon_monthly_note}
              //   </div>
              // );
            } else {
              htmlPart2 = (
                <ul className="popup-station-properties">
                  <li key={3}>
                    {objExpected.name}: {objExpected.value}
                  </li>
                  <li key={4}>
                    {props.baseline !== "GBON" &&
                    props.stationObject.default_schedule
                      ? "Default schedule"
                      : null}
                  </li>
                </ul>
              );
            }

            let centers = [];
            let centersValues = [];
            let maxIndex = 0;
            let max = 0;
            for (let i = 0; i < obsData.length; i++) {
              const element = obsData[i];
              let max_nr_expected = 0;
              if (max_nr_expected < element.nr_expected) {
                max_nr_expected = element.nr_expected;
              }
              if (!centers.includes(element.center)) {
                centers.push(element.center);
                centersValues.push({
                  type: "title",
                  value: element.center
                });
              }
              if (props.selectedPeriodType === "monthly") {
                if (element.nr_received > max) {
                  max = element.nr_received;
                  maxIndex = i;
                }
                centersValues.push({
                  type: "value",
                  // value: `#Received: ${element.nr_received} / ${element.nr_expected} `
                  value: `#Received: ${element.nr_received}`
                });
              } else {
                centersValues.push({
                  type: "value",
                  value: `#Received: ${element.nr_received}`
                });
              }

              if (
                (props.baseline === "GBON" && element.availability === 3) ||
                (props.baseline === "OSCAR" && element.availability !== 0)
              ) {
                centersValues.push({
                  type: "value",
                  value: `Completeness: ${getCompletenessText(
                    props,
                    element.availability
                  )}`
                });
              }
            }
            const htmlPart3 = centersValues.map((k, i) => {
              let value = k.value;
              if (
                props.selectedPeriodType === "monthly" &&
                props.selectedReport === "availability" &&
                k.value.includes("#Received")
              ) {
                // we do this formula to get the right index to bold it
                if (i === maxIndex + 1 || i === maxIndex * 3 + 1) {
                  value = <b> {k.value} </b>;
                }
              }
              let classElement;
              if (k.type === "title") {
                classElement = "popup-title-center";
              }
              return (
                <li key={i} className={classElement}>
                  {value}
                </li>
              );
            });

            const links = (
              <>
                {linkTimeSeries}
                <br />
                {linkOSCAR}
              </>
            );
            setContent(
              <>
                <ul className="popup-station-properties">{htmlPart1}</ul>
                {htmlExpectedMonthly}

                {htmlPart2}
                <ul className="popup-station-properties">{htmlPart3}</ul>
                {htmlNoteMonthly}
                {links}
              </>
            );
          });
        }
      } else {
        // TEMP Quality
        fetchData(
          `${URL_TEMP_AGGREGATED_OBSERVATIONS_POPUP}/?baseline=${props.baseline}&period_type=${props.selectedPeriodType}&report=${props.selectedReport}&station_id=${props.stationObject.station_id}&${periodsAsQP}&var_id=${props.selectedVariable}`
        ).then(response => {
          // PART 1 of the pop-up (Station name and WIGOS ID)
          let labelID = "WIGOS-ID";

          if (response.data[0].station === "Unknown") {
            labelID = "TSI";
            if (response.data[0].wigosid.includes("-")) {
              labelID = "WSI";
            }
          }
          // Used to fill in the title of the chart component
          props.onSetSelectedStation({
            name: response.data[0].station,
            wigosid: response.data[0].wigosid,
            in_oscar: response.data[0].in_oscar
          });

          const part1 = [
            `Name: ${response.data[0].station}`,
            `${labelID}: ${response.data[0].wigosid}`
          ];

          // PART 2 of the pop-up (values per center and level)
          let centers = [];
          let centersValues = [];
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i];
            if (!centers.includes(element.center)) {
              centers.push(element.center);
              centersValues.push({
                type: "title",
                value: element.center
              });
            }

            // Identify the value that is used on the map
            // so we can highlight it in the pop-up/
            // for that comparison we round the values to 10-5
            // We display all value to 10-3
            const toHighligth =
              Math.round(element.rms * 100000) / 100000 ===
              Math.round(props.stationObject.rms * 100000) / 100000;

            const value = element.rms
              ? Math.round(element.rms * multiplier * 1000) / 1000
              : "Not available";
            centersValues.push({
              type: "value",
              value: `${element.levels}: ${value}`,
              highlight: toHighligth
            });
          }

          const htmlPart1 = part1.map(v => {
            return <li key={v}>{v}</li>;
          });

          const htmlPart2 = centersValues.map(k => {
            let classElement;
            if (k.type === "title") {
              classElement = "popup-title-center";
            }
            if (k.highlight) {
              classElement = "popup-value-highlighted";
            }
            return (
              <li key={k.value} className={classElement}>
                {k.value}
              </li>
            );
          });

          const htmlContent = (
            <>
              {htmlPart1}
              {htmlPart2}
            </>
          );

          let linkOSCAR;

          let links = linkTimeSeries;

          if (response.data[0].station !== "Unknown") {
            linkOSCAR = (
              <>
                <LinkOSCAR wigosid={response.data[0].wigosid} />
              </>
            );
            links = (
              <>
                {linkTimeSeries}
                <br />
                <hr />
                {linkOSCAR}
              </>
            );
          }

          setContent(
            <ul className="popup-station-properties">
              {htmlContent}
              {links}
            </ul>
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.baseline]);
  let periodsAsQP;

  if (props.fileType !== "marine_surface") {
    periodsAsQP = getPeriodsAsQueryParameters(
      props.periodData,
      props.selectedPeriodType,
      props.selectedCenter,
      props.selectedDate,
      props.selectedSixHPeriod
    );
  } else if (props.fileType === "marine_surface") {
    periodsAsQP = getMarinePeriodsAsQueryParameters(
      props.stationObject.file_type.toUpperCase(),
      props.periodData,
      props.selectedPeriodType,
      props.selectedCenter,
      props.selectedDate,
      props.selectedSixHPeriod
    );
  }
  return (
    <>
      <button
        onClick={props.obj.remove}
        className="mapboxgl-popup-close-button"
        type="button"
        aria-label="Close popup"
      >
        ×
      </button>
      <div id="popup-content">{content}</div>
    </>
  );
};

export default Popup;
